import request from '@/utils/request'

export function documentCreate(data) {
  return request({
    url: '/document/create',
    method: 'POST',
    data
  })
}

// 获取供应链公司表

export function queryCompany(data) {
  return request({
    url: 'supplyChainCompany/list',
    method: 'GET',
    params: data
  })
}
//新增公司
export function createCompany(data) {
  return request({
    url: '/supplyChainCompany/create',
    method: 'POST',
    data
  })
}

// 编辑公司
export function resetCompany(data) {
  return request({
    url: '/supplyChainCompany/update',
    method: 'POST',
    data
  })
}

//删除供应链公司
export function deleteCompany(data) {
  return request({
    url: '/supplyChainCompany/remove',
    method: 'POST',
    params: data
  })
}
//获取供应链类型表
export function queryType(data) {
  return request({
    url: 'supplyChainType/list',
    method: 'GET',
    params: data
  })
}
// 新增供应链类型公司
export function createType(data) {
  return request({
    url: 'supplyChainType/create',
    method: 'POST',
    data
  })
}
//编辑供应链公司
export function resetType(data) {
  return request({
    url: 'supplyChainType/update',
    method: 'POST',
    data
  })
}
//新增供应链类型
export function documentRemove(id) {
  return request({
    url: '/document/remove',
    method: 'POST',
    params: {
      id
    }
  })
}
//更新供应链类型
export function documentUpdate(data) {
  return request({
    url: '/document/update',
    method: 'POST',
    data
  })
}
//删除供应链类型
export function deleteType(data) {
  return request({
    url: 'supplyChainType/remove',
    method: 'POST',
    params: data
  })
}



//订单管理表
export function orderList(data) {
  return request({
    url: 'order/form/list',
    method: 'GET',
    params: data
  })
}
//新增订单
export function addOrderList(data) {
  return request({
    url: '/order/form/order/create',
    method: 'POST',
    data
  })
}


//订单收发货状态

export function resetOrder(data) {
  return request({
    url: 'order/form/take/delivery/state',
    method: 'POST',
    data
  })
}
// 删除订单

export function deleteOrder(data) {
  return request({
    url: 'orderForm/remove',
    method: 'POST',
    params: data
  })
}

//  采购任务表

export function taskList(data) {
  return request({
    url: 'buyingTask/list',
    method: 'GET',
    params: data
  })
}
//新增采购任务


export function addByingTask(data) {
  return request({
    url: 'buyingTask/create',
    method: 'POST',
    data
  })
}
// 更新采购任务
export function resetByingTask(data) {
  return request({
    url: 'buyingTask/update',
    method: 'POST',
    data
  })
}
// 更新采购任务记录
export function updateByingTaskList(data) {
  return request({
    url: 'buyingTask/record/update',
    method: 'POST',
    data
  })
}


// 删除采购任务


export function deleteByingtask(data) {
  return request({
    url: 'buyingTask/remove',
    method: 'POST',
    params: data
  })
}

// 委托设计表
export function designList(data) {
  return request({
    url: 'entrustDesign/list',
    method: 'GET',
    params: data
  })
}
// 新增委托设计
export function addDesing(data) {
  return request({
    url: 'entrustDesign/create',
    method: 'POST',
    data
  })
}
// 编辑委托

export function resetDesing(data) {
  return request({
    url: 'entrustDesign/update',
    method: 'POST',
    data
  })
}

//新增委托删除

export function deleteDesginList(data) {
  return request({
    url: 'entrustDesign/remove',
    method: 'POST',
    params: data
  })
 
}
// 制造需求发布表
export function needList(data) {
  return request({
    url: 'manufacturing/release/issue/list',
    method: 'GET',
    params: data
  })
}

///广播制造需求发布

export function addmanuFacturing(data) {
  return request({
    url: 'manufacturing/release/broadcast/create',
    method: 'POST',
    data
  })
}

// //广播制造需求推送
// export function pushManuFacturing(data) {
//   return request({
//     url: 'manufacturing/release/push',
//     method: 'POST',
//     data
//   })
// }


 // 
 // 定向推送推送创建
 export function pushCreate(data) {
  return request({
    url: 'manufacturing/release/push/create',
    method: 'POST',
    data
  })
}
//   定向推送查询
export function searcDemandPush(data) {
  return request({
    url: 'manufacturing/release/push/list',
    method: 'GET',
    params: data
  })
}

//供应商制造生产任务表
export function gsyNeedList(data) {
  return request({
    url: 'manufacturingNeed/list',
    method: 'GET',
    params: data
  })
}

//供应商接受订单
export function acceptOrders(data) {
  return request({
    url: '/manufacturing/release/accept/update',
    method: 'POST',
    data
  })
}



//供应商拒绝单
export function  userReject(data) {
  return request({
    url: 'manufacturing/release/refuse/update',
    method: 'POST',
     data
  })
}

//制造需求删除
export function deleteNeedList(data) {
  return request({
    url: 'manufacturing/release/remove',
    method: 'GET',
    params: data
  })
}

 // 供应商更新进度
 export function userUpdate(data) {
  return request({
    url: 'order_form/updateNum',
    method: 'POST',
    data
  })
}

 //  供应商发货
 export function userSend(data) {
  return request({
    url: 'order_form/updateState',
    method: 'POST',
    data
  })
}


 // 海装收货
 export function userReslove(data) {
  return request({
    url: 'order_form/updateState',
    method: 'POST',
    data
  })
}


//删除订单
export function  userDeleteOrder(data) {
  return request({
    url: 'order_form/remove',
    method: 'POST',
    params:data
  })
}


//BOM 表
export function bomlist(data) {
  return request({
    url: '/bomTable/list',
    method: 'GET',
    params: data
  })
}
//获取BOM 类型
export function bomType(data) {
  return request({
    url: 'bomTable/getBomType',
    method: 'GET',
    params: data
  })
}
//新增BOM

export function addBomList(data) {
  return request({
    url: 'bomTable/create',
    method: 'POST',
    data
  })
}
//编辑BOM

export function resetBomList(data) {
  return request({
    url: 'bomTable/update',
    method: 'POST',
    data
  })
}
  //删除BOM 
  export function deleteBomList(data) {
    return request({
      url: 'bomTable/remove',
      method: 'POST',
      params:data
    })
  }



    // 合同表

    export function contractlist(data) {
      return request({
        url: 'contractManagement/list',
        method: 'GET',
        params: data
      })
    }

    //合同供应商
    export function supplierList(data) {
      return request({
        url: '/user/supplier/list',
        method: 'GET',
        params: data
      })
    }

 // 合同新增

 export   function addContractList(data){
  return  request({
    url:'contractManagement/create',
    method:'POST',
    data
  })
 }
 //更新合同

 export   function resetContractList(data){
  return  request({
    url:'contractManagement/contractOperation/create',
    method:'POST',
    data
  })
 }
 // 删除合同
 export   function deleteContractList(data){
  return  request({
    url:'contractManagement/remove',
    method:'POST',
    params:data
  })
 }



 // 产品表
 export function productList(data) {
  return request({
    url: 'newProduct/list',
    method: 'GET',
    params: data
  })
}

// 新增产品

export   function productAdd(data){
  return  request({
    url:'newProduct/create',
    method:'POST',
    data
  })
 }
 // 编辑产品
 export   function productUpdate(data){
  return  request({
    url:'newProduct/update',
    method:'POST',
    data
  })
 }
 //删除产品
 export   function deleteProductList(data){
  return  request({
    url:'newProduct/remove',
    method:'POST',
    params:data
  })
 }
  //文档查询
  export function documentList(data) {
    return request({
      url: 'archiveDocument/list',
      method: 'GET',
      params: data
    })
  }


export function documentupload(data) {
  return request({
    url: '/document/upload',
    method: 'POST',
    data
  })
}

  //文档资料查询
  export function fileInfornmation(data) {
    return request({
      url: '/documentation/list',
      method: 'GET',
      params: data
    })
  }

 //删除产品
 export   function deleteInformation(data){
  return  request({
    url:'/documentation/remove',
    method:'POST',
    params:data
  })
 }



// export function bomlist(params) {
//   return request({
//     url: '/document/list',
//     method: 'GET',
//     params: {
//       page: 1,
//       limit: 100,
//       type: data.type || null,
//     }
//   })
// }


export function FileLst(data) {
  return request({
    url: '/file/list',
    method: 'GET',
    params: data
  })
}

  //首页数据
  export function chartData(data) {
    return request({
      url: 'analyzeData/list',
      method: 'GET',
      params: data
    })
  }

  //评论查询
  export function commentSearch(data) {
    return request({
      url: 'orderComment/list',
      method: 'GET',
      params: data
    })
  }

  // 评论删除
  export function commentDelete(data) {
    return request({
      url: '/orderComment/remove',
      method: 'POST',
      params:data
    })
  }
  //评论打分
  export function commentReset(data) {
    return request({
      url: '/orderComment/contract/update',
      method: 'POST',
      data
    })
  }
 //运维方案查询
  export function planSearch(data) {
    return request({
      url: 'operation/service/list',
      method: 'GET',
      params: data
    })
  }
 //运维方案创建
 export function planCreate(data) {
  return request({
    url: 'operation/service/create',
    method: 'POST',
     data
  })
}

 //运维方案删除
 export function planDlete(data) {
  return request({
    url: '/operation/service/remove',
    method: 'GET',
    params: data
  })
}